<template>
  <div class="part-stocks">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova peça</span>
    </button>
    <b-table
      :data="allPartStocks"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.name }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.name }}</a>
        </template>
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ props.row.price }}</small>
                <br />
                <strong>Quantidade:</strong>
                <small>{{ props.row.quantity }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.price"
              ></b-input>
            </b-field>
            <b-field label="Quantidade (para valores em decimal, utilize .) *">
              <b-input
                required
                v-model="model.quantity"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/part-stocks/store/service'
import PartStock from '../models/part-stock'

export default {
  name: 'PartStocks',
  data() {
    return {
      model: PartStock.model,
      isCardModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('partStocks', ['allPartStocks']),
  },
  methods: {
    ...mapActions('partStocks', ['getAllPartStocks']),
    async init() {
      await this.getAllPartStocks()
    },
    onEdit(item) {
      item.price = parseFloat(item.price).toFixed(2)
      this.model = { ...item }
    },
    async save() {
      const partStock = {
        ...this.model,
      }

      partStock.price = partStock.price.replace('R$ ', '')

      try {
        const save = partStock.id
          ? await service.updatePartStock(partStock)
          : await service.savePartStock(partStock)

        await this.$success('Peça')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a peça?')

        if (result.isConfirmed) {
          await service.deletePartStock(id)

          await this.$delete('Peça')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
